// vendors
import React from "react"
import { css } from "@emotion/core"
import { Link as GatsbyLink } from "gatsby"

// components
import Link from "../Link/Link"
import { fontSizes, fonts, colors } from "../../styles/variables"
import mediaQuery from "../../utils/media-query"

const EventCard = ({
  picture,
  date,
  name,
  place,
  city,
  onlineUrl,
  excerpt,
  to,
}) => (
  <div
    css={css`
      max-width: 420px;
      width: 100%;
    `}
  >
    <GatsbyLink to={to}>
      <picture
        css={css`
          width: 100%;
          display: block;
          overflow: hidden;
          line-height: 0;
          position: relative;

          :before {
            display: block;
            content: "";
            padding-bottom: 100%;
          }

          > * {
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            object-fit: cover;
            width: 100%;
            height: 100%;
          }
        `}
      >
        <source sizes="" srcSet={picture.srcSetWebp} type="image/webp" />

        <img srcSet={picture.srcSet} src={picture.src} alt={picture.alt} />
      </picture>
    </GatsbyLink>

    <p
      css={css`
        font-size: ${fontSizes[2] / fontSizes[0]}em;
        font-family: ${fonts.accent};
        font-weight: bold;
        margin: ${fontSizes[2] / fontSizes[3]}em auto
          ${fontSizes[0] / fontSizes[2]}em;

        :after {
          width: 2ch;
          height: 2px;
          background-color: ${colors.darkTeal};
          content: "";
          display: block;
          margin-top: 0.25em;
        }

        ${mediaQuery.greaterThen(fontSizes.breakpoint)} {
          font-size: ${fontSizes[3] / fontSizes[1]}em;
        }
      `}
    >
      {new Date(date).toLocaleDateString("fr", {
        day: "2-digit",
        month: "long",
      })}
    </p>

    <h3
      css={css`
        font-size: ${fontSizes[3] / fontSizes[0]}em;
        line-height: ${fontSizes[4] / fontSizes[3]}em;
        font-weight: 900;
        font-family: ${fonts.body};
        margin: ${fontSizes[0] / fontSizes[3]}em auto
          ${fontSizes[0] / fontSizes[5]}em;

        ${mediaQuery.greaterThen(fontSizes.breakpoint)} {
          font-size: ${fontSizes[5] / fontSizes[1]}em;
        }
      `}
    >
      <GatsbyLink
        to={to}
        css={css`
          color: inherit;
          text-decoration: none;

          :hover,
          :focus {
            text-decoration: underline;
            /* color: ${colors.blueyGreen}; */
          }
        `}
      >
        {name}
        <br />
        {onlineUrl && (
          <span
            css={css`
              color: ${colors.darkPeach};
            `}
          >
            En ligne
          </span>
        )}
      </GatsbyLink>
    </h3>

    {(place || city) && (
      <p>
        {place}, {city}
      </p>
    )}

    <p>{excerpt}</p>

    <Link to={to} small>
      Détails
    </Link>
  </div>
)

export default EventCard
