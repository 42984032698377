// vendors
import React from "react"
import { Link as GatsbyLink } from "gatsby"
import { css, keyframes } from "@emotion/core"

// styles
import { colors, fonts, fontSizes, transition } from "./../../styles/variables"

import pinPointPastelRed from "../../images/pinPoint-pastelRed.svg"
import { greaterThen } from "../../utils/media-query"

const components = {
  link: GatsbyLink,
  a: ({ children, ...rest }) => <a {...rest}>{children}</a>,
  href: ({ children, ...rest }) => <a {...rest}>{children}</a>,
  button: ({ children, ...rest }) => <button {...rest}>{children}</button>,
}

const Link = ({ children, tag, to, small, ...rest }) => {
  const Tag = components[tag || "link"]

  const props = {
    to: !tag || tag === "link" ? to : undefined,
    href: tag === "a" || tag === "href" ? to : undefined,
    ...rest,
  }

  const generalStyle = css`
    appearance: none;
    margin: 0;
    padding: 0;
    border: 0;
    background: 0;
    text-align: left;
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    cursor: pointer;
    font-family: ${fonts.body};
    display: inline-block;
  `

  const smallStyle = css`
    ${generalStyle}
    color: ${colors.primary};
    text-transform: uppercase;
    transition: color 150ms ease-in-out;
    text-decoration: underline;
    font-size: 1em;

    &:hover {
      color: ${colors.text};
    }
  `

  const defaultStyle = css`
    ${generalStyle}
    position: relative;
    color: ${colors.text};
    text-decoration: none;
    font-size: ${24 / fontSizes[0]}em;
    line-height: ${30 / 24};
    font-weight: 700;
    max-width: 280px;
    padding-right: 36px;
    display: inline-block;
    margin: ${20 / 24}em 0;

    :before {
      position: absolute;
      top: calc(100% - 3px);
      display: block;
      background: ${colors.text};
      height: 4px;
      content: "";
      width: calc(100% - 36px);
      overflow: hidden;
      transition: width 150ms ${transition.curve.default};
      margin-top: 5px;
    }

    :after {
      position: absolute;
      right: 0;
      content: "";
      width: 27px;
      height: 33px;
      background-image: url(${pinPointPastelRed});
      background-repeat: no-repeat;
      background-position-y: 50%;
      margin-left: 5px;
      margin-bottom: 5px;
      transform: translateY(-100%);
      opacity: 0;
      bottom: 0;
      transition: transform 0ms ${transition.speed.fast},
        opacity ${transition.speed.fast} ease-in;
      transform-origin: bottom center;
    }

    &:hover {
      :before {
        width: 100%;
      }

      :after {
        transform: translateY(0);
        opacity: 1;
        transition: transform ${transition.speed.fast} ease-in 75ms,
          opacity ${transition.speed.fast} ease-in 75ms;
      }
    }

    ${greaterThen(fontSizes.breakpoint)} {
      font-size: ${36 / fontSizes[1]}em;
    }
  `

  const style = {
    "&&": {
      ...generalStyle,
      ...(!small && defaultStyle),
      ...(small && smallStyle),
    },
  }

  return (
    <Tag css={style} {...props}>
      {children}
    </Tag>
  )
}

export default Link
