// vendors
import React from "react"

const IllustrationPathWithPin = ({
  dot = false,
  pointer = false,
  ...props
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 238.91 215.02"
      {...props}
    >
      <path
        d="M177.4,127.24A83.49,83.49,0,1,1,79.78,16"
        fill="none"
        stroke="#2bb673"
        strokeLinecap="round"
        strokeMiterlimit="10"
        strokeWidth="32"
      />

      {pointer && (
        <g>
          <path
            d="M177.4,66a61.59,61.59,0,0,0-61.52,61.52c0,22.12,16.76,44.76,30.82,59.85A244.67,244.67,0,0,0,177.4,215a240.64,240.64,0,0,0,30.71-27.52c14-15.06,30.8-37.69,30.8-60A61.58,61.58,0,0,0,177.4,66Zm.84,121-.82.76-.83-.76c-16.9-15.38-37.05-38.64-37.05-62.09a37.87,37.87,0,0,1,70.28-19.59C223.13,127.37,211.91,156.38,178.24,187Z"
            fill="#e66752"
          />
          <path
            d="M180.5,111.51a16.67,16.67,0,0,0-3.2-.31,16.09,16.09,0,0,0-15.73,19.22,16.26,16.26,0,0,0,14,12.8,16.06,16.06,0,0,0,5-31.71Z"
            fill="#e66752"
          />
        </g>
      )}

      {dot && (
        <path
          d="M99.87,83.36a16.6,16.6,0,0,0-3.2-.31,16.09,16.09,0,0,0-15.73,19.22,16.28,16.28,0,0,0,14,12.8,16.06,16.06,0,0,0,5-31.71Z"
          fill="#e66752"
        />
      )}
    </svg>
  )
}

export default IllustrationPathWithPin
