// vendors
import React from "react"
import { css } from "@emotion/core"

// components
import EventCard from "../EventCard"

// helpers
import { fontSizes } from "../../styles/variables"
import mediaQuery from "../../utils/media-query"

const EventGrid = ({ events, breakpoint = 1024, ...props }) => (
  <div
    css={css`
      display: grid;
      grid-template-columns: repeat(auto-fit, minmax(260px, 1fr));
      justify-items: center;
      grid-gap: ${fontSizes[9] / fontSizes[1]}em;

      ${mediaQuery.greaterThen(breakpoint)} {
        grid-template-columns: repeat(auto-fit, minmax(360px, 1fr));
      }
    `}
  >
    {events.map((event) => (
      <EventCard key={event.id} {...event} />
    ))}
  </div>
)

export default EventGrid
